// Local Variables

.SidebarMenu {
  padding-left: 20px;
  position: fixed;
}

.CategoriesTitle {
}

.CategoriesTitle p {
  text-align: center;
  text-transform: uppercase;
  width: 80px;
  height: 18px;
  font-family: Futura;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #434c5f;
}

.dividerLine {
  height: 1px;
  opacity: 0.54;
  width: 174px;
  border: solid 1px #d4d9e2;
  width: 174px;
}

.MenuItem {
  padding: 11px 0 !important;
}

.IconWrapperActive {
  width: 32px;
  height: 32px;
  padding: 0;
  object-fit: contain;
  display: flex;
  background-image: url("../images/icons/catActive.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50px;
  align-content: center;
}

.IconWrapper {
  width: 32px;
  height: 32px;
  padding: 0;
  object-fit: contain;
  display: flex;
  align-content: center;
  border-radius: 50px;
}

.TumblerIcon {
  background-image: url("../images/icons/tumbler.svg");
}

.CoffeeIcon {
  background-image: url("../images/icons/coffee.svg");
}

.TshirtIcon {
  background-image: url("../images/icons/tshirts.svg");
}

.OuterwearIcon {
  background-image: url("../images/icons/outerwear.svg");
}

.HatsIcon {
  background-image: url("../images/icons/hats.svg");
}

.NotebookIcon {
  background-image: url("../images/icons/notebooks.svg");
}

.BackpacksIcon {
  background-image: url("../images/icons/backpacks.svg");
}

.ToteIcon {
  background-image: url("../images/icons/tote.svg");
}

.WaterBottlesIcon {
  background-image: url("../images/icons/water-bottles.svg");
}
//Active Icons
.TumblerIconActive {
  background-image: url("../images/icons/icons-active/travel-tumblers-blue.svg");
}

.CoffeeIconActive {
  background-image: url("../images/icons/icons-active/coffee-mugs-blue.svg");
}

.TshirtIconActive {
  background-image: url("../images/icons/icons-active/t-shirts-blue.svg");
}

.OuterwearIconActive {
  background-image: url("../images/icons/icons-active/outerwear-blue.svg");
}

.HatsIconActive {
  background-image: url("../images/icons/icons-active/hats-blue.svg");
}

.NotebookIconActive {
  background-image: url("../images/icons/icons-active/notebooks-blue.svg");
}

.BackpacksIconActive {
  background-image: url("../images/icons/icons-active/backpacks-blue.svg");
}

.ToteIconActive {
  background-image: url("../images/icons/icons-active/bags-blue.svg");
}

.WaterBottlesIconActive {
  background-image: url("../images/icons/icons-active/water-bottles.svg");
}

.Icon {
  width: 32px;
  height: 32px;
  object-fit: contain;
  display: flex;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-content: center;
}

.ItemText,
.ItemTextActive {
  padding: 0 !important;
}

.ItemText span {
  width: 70px;
  height: 12px;
  opacity: 0.54;
  font-family: FuturaLTPro;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #434c5f;
}

.ItemTextActive span {
  width: 70px;
  height: 12px;
  font-family: FuturaLTPro;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3577d4 !important;
}

// I need to find a best way to do this
.MuiListItemIcon-root-241 {
  display: inline-flex;
  flex-shrink: 0;
  margin-right: 0px !important;
}

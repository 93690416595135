.Nav {
  // width: 1440px;
  height: 107px;
  background-color: $white;
  // max-width: 1440px;
  box-shadow: none;
}
.logo {
  width: 160.9px;
  height: 48px;
  object-fit: contain;
}
.start-btn {
  width: 180px !important;
  height: 48px !important;
  border-radius: 4px !important;
  background-color: $blue;
  font-family: Futura !important;
  font-size: 16px !important;
  font-weight: bold !important;
  font-style: normal !important;
  font-stretch: normal !important;
  line-height: 1.75 !important;
  letter-spacing: normal !important;
  text-align: center !important;
  color: $white;
}
.menu-item {
  // width: 103px;
  // height: 18px;
  font-family: Futura !important;
  font-size: 12px !important;
  font-weight: bold !important;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center !important;
  color: $gray !important;
}

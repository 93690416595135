.CartBar {
  right: 0;
  width: 300px;
  height: 100%;
  padding: 110px 0px;
  position: fixed;
  background-color: $dark-white;
  border: solid 1px $light-gray;
}
.CartTitle {
  text-transform: uppercase;
  width: 300px;
  height: 18px;
  padding: 5px 15px 30px 15px;
  font-family: Futura;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #434c5f;
  border-bottom: 1px solid $light-gray;
}
.CartList {
  padding-left: 0px;
  margin-top: 0px;
}
.CartElement {
  list-style: none;
  padding-top: 10px;
  padding-left: 15px;
  padding-bottom: 10px;
  display: flex;
  border-bottom: 1px solid $light-gray;
}
.ItemImg {
  width: 42px;
  height: 42px;
  border-radius: 2px;
  border: solid 1px $light-gray;
}

.ItemImg img {
  width: 40px;
  height: 40px;
}

.ItemDesc {
  padding-left: 15px;
}

.ItemTitle {
  width: 127px;
  height: 14px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: FuturaLTPro;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $gray;
}

.ItemPrice {
  width: 63px;
  height: 11px;
  margin: 0;
  opacity: 0.55;
  font-family: FuturaLTPro;
  font-size: 11px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $gray;
}

.ItemActions {
  width: 100%;
  text-align: right;
}

.CartActions {
  bottom: 0;
  position: fixed;
  width: 100%;
  padding: 20px 15px 20px 15px;
}

// I need to find the way to remove the !important
.cart-btn {
  width: 268px !important;
  height: 56px !important;
  border-radius: 4px !important;
  background-color: $blue !important;
  font-family: Futura !important;
  font-size: 16px !important;
  font-weight: bold !important;
  font-style: normal !important;
  font-stretch: normal !important;
  line-height: 1.75 !important;
  letter-spacing: normal !important;
  text-align: center !important;
  color: $white;
}

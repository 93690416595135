@import url(//hello.myfonts.net/count/3842d3);
@import url(//fonts.googleapis.com/css?family=Cabin|PT+Sans);
@font-face {font-family: 'FuturaLTPro';src: url(/static/media/3842D3_0_0.2da08597.eot);src: url(/static/media/3842D3_0_0.2da08597.eot?#iefix) format('embedded-opentype'),url(/static/media/3842D3_0_0.636c1d83.woff2) format('woff2'),url(/static/media/3842D3_0_0.97c9a523.woff) format('woff'),url(/static/media/3842D3_0_0.0907a89e.ttf) format('truetype');}
@font-face {font-family: 'Futura';src: url(/static/media/3842F8_0_0.899549c6.eot);src: url(/static/media/3842F8_0_0.899549c6.eot?#iefix) format('embedded-opentype'),url(/static/media/3842F8_0_0.db540479.woff2) format('woff2'),url(/static/media/3842F8_0_0.00b0863e.woff) format('woff'),url(/static/media/3842F8_0_0.a30ed07f.ttf) format('truetype');}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

.Nav {
  height: 107px;
  background-color: "#ffffff";
  box-shadow: none; }

.logo {
  width: 160.9px;
  height: 48px;
  object-fit: contain; }

.start-btn {
  width: 180px !important;
  height: 48px !important;
  border-radius: 4px !important;
  background-color: #3577d4;
  font-family: Futura !important;
  font-size: 16px !important;
  font-weight: bold !important;
  font-style: normal !important;
  font-stretch: normal !important;
  line-height: 1.75 !important;
  letter-spacing: normal !important;
  text-align: center !important;
  color: "#ffffff"; }

.menu-item {
  font-family: Futura !important;
  font-size: 12px !important;
  font-weight: bold !important;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center !important;
  color: #434c5f !important; }

.MainContainer {
  padding-top: 106px;
  padding-bottom: 100px; }

.SidebarMenu {
  padding-left: 20px;
  position: fixed; }

.CategoriesTitle p {
  text-align: center;
  text-transform: uppercase;
  width: 80px;
  height: 18px;
  font-family: Futura;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #434c5f; }

.dividerLine {
  height: 1px;
  opacity: 0.54;
  width: 174px;
  border: solid 1px #d4d9e2;
  width: 174px; }

.MenuItem {
  padding: 11px 0 !important; }

.IconWrapperActive {
  width: 32px;
  height: 32px;
  padding: 0;
  object-fit: contain;
  display: flex;
  background-image: url(/static/media/catActive.a496da40.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50px;
  align-content: center; }

.IconWrapper {
  width: 32px;
  height: 32px;
  padding: 0;
  object-fit: contain;
  display: flex;
  align-content: center;
  border-radius: 50px; }

.TumblerIcon {
  background-image: url(/static/media/tumbler.c7a1f322.svg); }

.CoffeeIcon {
  background-image: url(/static/media/coffee.24c12ca6.svg); }

.TshirtIcon {
  background-image: url(/static/media/tshirts.e826a8ce.svg); }

.OuterwearIcon {
  background-image: url(/static/media/outerwear.656a22c1.svg); }

.HatsIcon {
  background-image: url(/static/media/hats.f50a1503.svg); }

.NotebookIcon {
  background-image: url(/static/media/notebooks.e175e4c6.svg); }

.BackpacksIcon {
  background-image: url(/static/media/backpacks.f2ca6874.svg); }

.ToteIcon {
  background-image: url(/static/media/tote.502bebc1.svg); }

.WaterBottlesIcon {
  background-image: url(/static/media/water-bottles.2357da29.svg); }

.TumblerIconActive {
  background-image: url(/static/media/travel-tumblers-blue.9851e028.svg); }

.CoffeeIconActive {
  background-image: url(/static/media/coffee-mugs-blue.a69ce854.svg); }

.TshirtIconActive {
  background-image: url(/static/media/t-shirts-blue.a7e1eb25.svg); }

.OuterwearIconActive {
  background-image: url(/static/media/outerwear-blue.87d2944f.svg); }

.HatsIconActive {
  background-image: url(/static/media/hats-blue.18b574c5.svg); }

.NotebookIconActive {
  background-image: url(/static/media/notebooks-blue.8912dc51.svg); }

.BackpacksIconActive {
  background-image: url(/static/media/backpacks-blue.2216b783.svg); }

.ToteIconActive {
  background-image: url(/static/media/bags-blue.4eedd234.svg); }

.WaterBottlesIconActive {
  background-image: url(/static/media/water-bottles.a2cf1f56.svg); }

.Icon {
  width: 32px;
  height: 32px;
  object-fit: contain;
  display: flex;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-content: center; }

.ItemText,
.ItemTextActive {
  padding: 0 !important; }

.ItemText span {
  width: 70px;
  height: 12px;
  opacity: 0.54;
  font-family: FuturaLTPro;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #434c5f; }

.ItemTextActive span {
  width: 70px;
  height: 12px;
  font-family: FuturaLTPro;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3577d4 !important; }

.MuiListItemIcon-root-241 {
  display: inline-flex;
  flex-shrink: 0;
  margin-right: 0px !important; }

.Breadcrumb {
  width: 30px;
  height: 12px;
  font-family: FuturaLTPro;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #434c5f; }

.BlockTitle {
  width: 654px;
  height: 31px;
  font-family: FuturaLTPro;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3577d4; }

.BoldText {
  font-family: Futura;
  font-weight: bold; }

.Card {
  width: 257px;
  height: 336px;
  border-radius: 4px;
  border: solid 1px #d4d9e2;
  margin: 0 auto; }

.CardTitle {
  width: 59px;
  height: 18px;
  font-family: Futura;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #434c5f; }

.Price {
  width: 73px;
  height: 12px;
  opacity: 0.55;
  font-family: FuturaLTPro;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #434c5f; }

.AddButton {
  width: 36px !important;
  height: 36px !important;
  object-fit: contain;
  background-image: url(/static/media/add-icon.5da83100.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-content: center; }

.CartBar {
  right: 0;
  width: 300px;
  height: 100%;
  padding: 110px 0px;
  position: fixed;
  background-color: #fafafa;
  border: solid 1px #d4d9e2; }

.CartTitle {
  text-transform: uppercase;
  width: 300px;
  height: 18px;
  padding: 5px 15px 30px 15px;
  font-family: Futura;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #434c5f;
  border-bottom: 1px solid #d4d9e2; }

.CartList {
  padding-left: 0px;
  margin-top: 0px; }

.CartElement {
  list-style: none;
  padding-top: 10px;
  padding-left: 15px;
  padding-bottom: 10px;
  display: flex;
  border-bottom: 1px solid #d4d9e2; }

.ItemImg {
  width: 42px;
  height: 42px;
  border-radius: 2px;
  border: solid 1px #d4d9e2; }

.ItemImg img {
  width: 40px;
  height: 40px; }

.ItemDesc {
  padding-left: 15px; }

.ItemTitle {
  width: 127px;
  height: 14px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: FuturaLTPro;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #434c5f; }

.ItemPrice {
  width: 63px;
  height: 11px;
  margin: 0;
  opacity: 0.55;
  font-family: FuturaLTPro;
  font-size: 11px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #434c5f; }

.ItemActions {
  width: 100%;
  text-align: right; }

.CartActions {
  bottom: 0;
  position: fixed;
  width: 100%;
  padding: 20px 15px 20px 15px; }

.cart-btn {
  width: 268px !important;
  height: 56px !important;
  border-radius: 4px !important;
  background-color: #3577d4 !important;
  font-family: Futura !important;
  font-size: 16px !important;
  font-weight: bold !important;
  font-style: normal !important;
  font-stretch: normal !important;
  line-height: 1.75 !important;
  letter-spacing: normal !important;
  text-align: center !important;
  color: "#ffffff"; }

.Sticker {
  width: 290px;
  height: 18px;
  font-family: FuturaLTPro;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #434c5f; }

.FormTopContainer {
  padding: 10px 30px;
  border-bottom: 1px solid #d4d9e2; }

.FormContainer {
  padding-left: 30px; }

.SectionHeading {
  width: 100%;
  height: 24px;
  font-family: FuturaLTPro;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #434c5f; }

.Required {
  width: 53px;
  height: 12px;
  opacity: 0.54;
  font-family: FuturaLTPro;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #434c5f; }

.label {
  width: 100%;
  height: 25px;
  padding-top: 15px;
  font-family: Futura;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #434c5f; }

.PlaceholderLabel {
  width: 100%;
  height: 21px;
  opacity: 0.54;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #434c5f; }

.SmallText {
  width: 237px;
  height: 14px;
  opacity: 0.54;
  font-family: FuturaLTPro;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #434c5f; }

.DropArea {
  width: 435px;
  height: 231px;
  padding-top: 10px;
  border-radius: 4px;
  background-color: #fafafa; }

.DropAreatext {
  width: 190px;
  margin: 0 auto;
  text-align: center; }

.DropAreatext h3 {
  text-transform: uppercase;
  height: 19px;
  margin-bottom: 5px;
  opacity: 0.54;
  font-family: Futura;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.58;
  letter-spacing: normal;
  text-align: center;
  color: #434c5f; }

.DropAreatext p {
  width: 190px;
  height: 38px;
  font-family: Futura;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: center;
  color: #434c5f; }

.DropAreatext p > small {
  font-family: FuturaLTPro;
  font-weight: normal; }

.DropAreatext p > small > a {
  font-family: FuturaLTPro;
  font-weight: normal;
  color: #3577d4; }

.IconUpload {
  width: 80px;
  height: 80px;
  object-fit: contain;
  opacity: 0.54;
  background-image: url(/static/media/cloud-upload.eefa29d6.svg);
  background-position: center;
  background-repeat: no-repeat;
  align-content: center;
  margin: 0 auto; }

.AddButton {
  box-shadow: none !important; }


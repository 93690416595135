.Breadcrumb {
  width: 30px;
  height: 12px;
  font-family: FuturaLTPro;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #434c5f;
}

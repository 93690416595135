.Sticker {
  width: 290px;
  height: 18px;
  font-family: FuturaLTPro;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #434c5f;
}

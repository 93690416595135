.BlockTitle {
  width: 654px;
  height: 31px;
  font-family: FuturaLTPro;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $blue;
}

.BoldText {
  font-family: Futura;
  font-weight: bold;
}
.Card {
  width: 257px;
  height: 336px;
  border-radius: 4px;
  border: solid 1px $light-gray;
  margin: 0 auto;
}
.CardTitle {
  width: 59px;
  height: 18px;
  font-family: Futura;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #434c5f;
}
.Price {
  width: 73px;
  height: 12px;
  opacity: 0.55;
  font-family: FuturaLTPro;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #434c5f;
}

.AddButton {
  width: 36px !important ;
  height: 36px !important;
  object-fit: contain;
  background-image: url("../images/icons/add-icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-content: center;
}

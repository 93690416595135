.FormTopContainer {
  padding: 10px 30px;
  border-bottom: 1px solid $light-gray;
}

.FormContainer {
  padding-left: 30px;
}

.SectionHeading {
  width: 100%;
  height: 24px;
  font-family: FuturaLTPro;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $gray;
}

.Required {
  width: 53px;
  height: 12px;
  opacity: 0.54;
  font-family: FuturaLTPro;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #434c5f;
}

.label {
  width: 100%;
  height: 25px;
  padding-top: 15px;
  font-family: Futura;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $gray;
}

.PlaceholderLabel {
  width: 100%;
  height: 21px;
  opacity: 0.54;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $gray;
}

.SmallText {
  width: 237px;
  height: 14px;
  opacity: 0.54;
  font-family: FuturaLTPro;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $gray;
}

.DropArea {
  width: 435px;
  height: 231px;
  padding-top: 10px;
  border-radius: 4px;
  background-color: $dark-white;
}

.DropAreatext {
  width: 190px;
  margin: 0 auto;
  text-align: center;
}

.DropAreatext h3 {
  text-transform: uppercase;
  height: 19px;
  margin-bottom: 5px;
  opacity: 0.54;
  font-family: Futura;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.58;
  letter-spacing: normal;
  text-align: center;
  color: $gray;
}

.DropAreatext p {
  width: 190px;
  height: 38px;
  font-family: Futura;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: center;
  color: $gray;
}

.DropAreatext p > small {
  font-family: FuturaLTPro;
  font-weight: normal;
}

.DropAreatext p > small > a {
  font-family: FuturaLTPro;
  font-weight: normal;
  color: #3577d4;
}

.IconUpload {
  width: 80px;
  height: 80px;
  object-fit: contain;
  opacity: 0.54;
  background-image: url(../images/icons/cloud-upload.svg);
  background-position: center;
  background-repeat: no-repeat;
  align-content: center;
  margin: 0 auto;
}
